import { useCallback, useEffect } from 'react';
import isEmpty from 'lodash/isEmpty';

import { TeamNanoID, TeamOwnerNanoID } from '../../../../teamsTypes';
import {
  CompanyWithdrawFormData,
  CompanyWithdrawFormFields
} from '../CompanyWithdrawForm.types';

import {
  FETCH_USER_SETTINGS_QUERY,
  FetchUserSettingsQueryResponse
} from '../../../../../users/queries/fetchUserSettings.query';

import { useCompanyBalanceAccounts } from '../../../../../accounts/hooks/useCompanyBalanceAccounts';
import { useReactHookForm } from '../../../../../common/hooks/base/useReactHookForm';
import { useUser } from '../../../../../users/hooks/useUser';
import { useCreatePayoneerV4Payout } from '../../../../../payoneerV4Payouts/hooks/useCreatePayoneerV4Payout';
import { usePerformRequestPayoneerV4Payout } from '../../../../../payoneerV4Payouts/hooks/usePerformRequestPayoneerV4Payout';

import { getUserPayoneerFieldValueByType } from '../../../../../users/utils/getUserPayoneerFieldValueByType';

import { companyWithdrawFormShema } from './useCompanyWithdrawForm.schema';

import { UserCache } from '../../../../../users/UserCache';

import {
  teamPayoutPayoneerAccountTypes,
  teamTextPayoneerAccountTypes
} from '../../../../teamsConstants';

interface CompanyWithdrawFormOptions {
  companyNanoId: TeamNanoID;
  companyOwnerNanoId: TeamOwnerNanoID;
}

function useCompanyWithdrawForm({
  companyNanoId,
  companyOwnerNanoId
}: CompanyWithdrawFormOptions) {
  const {
    companyBalance,
    companyCashAccount,
    companyRevenueReverseDebtAccount,
    companyBalanceAccountsErrorMessage,
    companyBalanceAccountsFetched
  } = useCompanyBalanceAccounts({ companyNanoId });

  const { user } = useUser<FetchUserSettingsQueryResponse>({
    cacheKey: UserCache.userSettingsCacheKey(),
    uuid: companyOwnerNanoId,
    query: FETCH_USER_SETTINGS_QUERY
  });

  const {
    control,
    errors,
    handleSubmitReactHookForm,
    register,
    resetForm,
    setValue,
    watch
  } = useReactHookForm<CompanyWithdrawFormData>({
    defaultValues: {
      amount: companyBalance,
      payonnerAccountType: user?.payoneerIdType
    },
    isModalForm: true,
    schema: companyWithdrawFormShema(
      (companyCashAccount?.balance > 0 && companyCashAccount?.balance) || 0
    )
  });

  const resetCompanyWithdrawAmountForm = useCallback(() => {
    resetForm();

    setValue(
      CompanyWithdrawFormFields.AMOUNT,
      (companyBalance > 0 && companyBalance) || 0
    );
    setValue(
      CompanyWithdrawFormFields.PAYONEER_ACCOUNT_TYPE,
      user?.payoneerIdType
    );
  }, [companyBalance, resetForm, setValue, user?.payoneerIdType]);

  useEffect(() => {
    resetCompanyWithdrawAmountForm();
  }, [resetCompanyWithdrawAmountForm]);

  const {
    createPayoneerV4PayoutLoading,
    createPayoneerV4PayoutErrorMessage,
    createPayoneerV4PayoutReset,
    createPayoneerV4Payout
  } = useCreatePayoneerV4Payout({});

  const {
    performRequestPayoneerV4PayoutLoading,
    performRequestPayoneerV4PayoutErrorMessage,
    performRequestPayoneerV4Payout,
    performRequestPayoneerV4PayoutReset
  } = usePerformRequestPayoneerV4Payout({});

  const watchAmount = watch(CompanyWithdrawFormFields.AMOUNT);

  useEffect(() => {
    const amount = watchAmount > companyBalance ? companyBalance : watchAmount;

    setValue(
      CompanyWithdrawFormFields.AMOUNT,
      (amount >= 0 && amount) || undefined
    );
  }, [companyBalance, setValue, watchAmount]);

  return {
    companyCashAccount,
    companyRevenueReverseDebtAccount,
    companyBalance,
    companyBalanceAccountsErrorMessage,
    companyBalanceAccountsFetched,
    control,
    validationErrors: {
      amountValidationError: errors?.amount?.message,
      payoneerAccountTypeValidationError: errors?.payonnerAccountType?.message
    },
    resetCompanyWithdrawAmountForm: resetCompanyWithdrawAmountForm,
    companyWithdrawAmountReset: useCallback(() => {
      performRequestPayoneerV4PayoutReset();
      createPayoneerV4PayoutReset();
    }, [createPayoneerV4PayoutReset, performRequestPayoneerV4PayoutReset]),
    companyWithdrawAmountErrorMessage:
      createPayoneerV4PayoutErrorMessage ||
      performRequestPayoneerV4PayoutErrorMessage,
    companyWithdrawAmountLoading:
      createPayoneerV4PayoutLoading || performRequestPayoneerV4PayoutLoading,
    handleCompanyWithdrawAmount: handleSubmitReactHookForm({
      onSubmit: async (data) => {
        const description = `${
          teamTextPayoneerAccountTypes[data.payonnerAccountType]
        } ${new Date().toLocaleDateString('ru-RU')}`;

        const payoneerV4Payout = await createPayoneerV4Payout({
          payoneerV4Payout: {
            amount: data.amount,
            description,
            payee_id: user?.id,
            program_key:
              teamPayoutPayoneerAccountTypes[data.payonnerAccountType]
          }
        });

        await performRequestPayoneerV4Payout({
          payoneerV4PayoutId: payoneerV4Payout.id
        });
      },
      dirtyFieldsOnly: false
    }),
    registerFields: {
      registerAmount: register(CompanyWithdrawFormFields.AMOUNT)
    },
    watchAmount,
    submitDisabled: isEmpty(getUserPayoneerFieldValueByType(user))
  };
}

export default useCompanyWithdrawForm;
